<template>
    <div class="one-click-subscribe-email-view ViewWrapper">
        <div class="container">
            <div class="row" v-if="fetchingPortalInformation">
                <core-loader :active="true" :with-padding="true"></core-loader>
            </div>
            <div class="row" v-if="displayMain">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <!-- LOGO -->
                    <tenant-branding-logo></tenant-branding-logo>

                    <!-- BODY -->
                    <div class="gk-card no-border my-5 position-relative">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="subscribing" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Email Communication Subscription') }}</p>
                            <p class="mb-0 text-center weight-5 size-6" v-if="hasProbValidTranslation('branding.name')">{{ $t('branding.name') }}</p>
                        </div>

                        <form v-on:submit.prevent="submit" v-if="!subscriptionSucceed" data-vv-scope="subscribeEmailForm" class="text-center">
                            <p>{{ $t(messageKeyBasedOnType) }}</p>
                            <div v-if="action === 'subscribe'">
                                <button type="button" class="btn btn-primary" @click="submit">{{ $t('Subscribe') }}</button>
                            </div>
                            <div v-else>
                                <button type="button" class="btn btn-danger" @click="submit">{{ $t('Unsubscribe') }}</button>
                            </div>
                        </form>

                        <div class="gk-card text-center" v-if="subscriptionSucceed">
                            <p class="weight-3 size-5">{{ $t('Well done') }}</p>
                            <p class="weight-4 text-success mb-0" v-if="action === 'subscribe'">{{ $t('Your subscription has been submitted') }}</p>
                            <p class="weight-4 text-danger mb-0" v-else>{{ $t('Your subscription has been canceled') }}</p>
                        </div>
                    </div>

                    <tenant-box-footer :display-i18n-links="portalInformation.i18nAddOnEnabled"
                                       :display-cloutlayer-brand="portalInformation.productBranding"></tenant-box-footer>
                    <!-- This footer has the Cloutlayer's information. -->
                    <!--
                    <common-box-footer></common-box-footer>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EmailSubscriptionService } from '../common/services/api.service';
import PortalInformationAccessMixin from './Mixins/PortalInformationAccessMixin';
import TenantBoxFooter from './Snippets/TenantBoxFooter';
import TenantBrandingLogo from './Snippets/TenantBrandingLogo';

export default {
    name: 'OneClickSubscribeEmail',
    components: {
        TenantBrandingLogo,
        TenantBoxFooter
    },
    mixins: [
        PortalInformationAccessMixin('OneClickSubscribeEmail')
    ],
    data () {
        return {
            subscribing: false,
            subscriptionSucceed: false, // If true, means that the process ended and the user successfully subscribed.
            // Provided from query data //////////
            emailIdentityProfileId: null,
            emailCampaignId: null,
            type: null
        };
    },
    computed: {
        displayMain () {
            if (this.fetchingPortalInformation) {
                return false;
            }
            return this.portalInformation !== null;
        },
        displayForm () {
            return !this.subscriptionSucceed;
        },
        displaySuccessMessage () {
            if (this.subscribing) {
                return false;
            }
            return this.subscriptionSucceed;
        },
        messageKeyBasedOnType () {
            if (this.type === 'MARKETING_EMAIL') {
                return 'Type_Marketing_Email';
            } else if (this.type === 'ONE_TO_ONE_EMAIL') {
                return 'Type_Personal_Email';
            } else {
                return '';
            }
        }
    },
    mounted () {
        this.explainRoute().then((result) => {
            if (!result.tenantFound) {
                // Tenant not found. Redirect to the front page.
                window.location.replace('/');
            }
            this.emailIdentityProfileId = this.$route.query.eipid;
            if (!this.emailIdentityProfileId) {
                // EmailIdentityProfileId not found. Redirect to the front page.
                window.location.replace('/');
            }
            this.emailCampaignId = this.$route.query.ecid;

            // Get and check the type.
            this.type = this.$route.query.type;
            if (!this.type) {
                this.type = 'MARKETING_EMAIL';
            } else {
                // To Upper Case.
                this.type = this.type.toUpperCase();

                // Check if type has valid literal or set the default.
                if (this.type !== 'MARKETING_EMAIL' && this.type !== 'ONE_TO_ONE_EMAIL') {
                    this.type = 'MARKETING_EMAIL';
                }
            }
        });
    },
    methods: {
        async submit () {
            // @future : Add prompt...

            // prevent duplicate.
            // @future : add to all forms in all projects?
            if (this.subscribing) {
                return;
            }

            // Start loader.
            this.subscribing = true;

            // Make the requests.
            // let result1 = null;
            // let result2 = null;
            const dataObj = {
                'emailIdentityProfileId': this.emailIdentityProfileId,
                'emailCampaignId': this.emailCampaignId,
                'status': null,
                'withNotification': false,
                'withConfirmation': false,
                'withDataProcessingConsent': true, // @future Add checkbox!
                'types': [],
                'tenantId': this.tenantId
            };

            // Add the type.
            dataObj.types.push(this.type);

            // Add status. It is impossible for action to have a value which is not equal to 'subscribe' or 'unsubscribe'.
            if (this.action === 'subscribe') {
                dataObj.status = 'OPTED_IN';
            } else if (this.action === 'unsubscribe') {
                dataObj.status = 'OPTED_OUT';
            }

            await EmailSubscriptionService.subscribe(dataObj).then(({ data }) => {
                return Promise.resolve({
                    succeed: true,
                    data: data,
                    error: null
                });
            }).catch((reason) => {
                return Promise.resolve({
                    succeed: false,
                    data: null,
                    error: reason
                });
            });

            // We do not check the results. We except that both requests succeed.
            // The backend implementation if ultra safe if the email is valid.
            // @future : Implement a more reliable mechanism.

            // Stop loader.
            this.subscribing = false;

            // Set confirmation to succeed.
            this.subscriptionSucceed = true;
        }
    }
};
</script>
